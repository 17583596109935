import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { scopePartToPath } from '../../../utils/scopeModifs'
import { useElementContext } from '../../context/ElementContext'
import { useFormRenderContext } from '../../context/RenderFormContext'
import DynamEnumWatch from '../../form_elements/DynamEnumWatch'
import { iUIschema } from '../../interfaces/iUiSchema'
import RenderElements from '../../renders/RenderElements'
import { modifUiSchemaArray } from '../../utils/modifUiSchemaArray'

const ArrayGroupTxt = () => {
  const { dateSchemaScope } = useFormRenderContext()
  const { uiSchema, path, id, dataSchema } = useElementContext()
  const { scope, elements, label } = uiSchema

  const { fields } = useFieldArray({ name: path })

  const identifierScope = uiSchema.scope + '/' + uiSchema.options?.arrayIdentifierScope
  const idElement = identifierScope ? dateSchemaScope[identifierScope] : undefined
  const idRequired = idElement ? idElement?.validations?.required : true

  const min = dataSchema?.validations?.minItems ?? idRequired ? 1 : 0

  if (min && !fields.length) {
    fields.push({} as any)
  }

  const identifierRelativePath = uiSchema.options?.arrayIdentifierScope
    ? scopePartToPath(uiSchema.options?.arrayIdentifierScope)
    : undefined

  const labelRelativePath = uiSchema.options?.arrayLabelScope
    ? scopePartToPath(uiSchema.options?.arrayLabelScope)
    : undefined

  return (
    <div id={id}>
      {scope && identifierRelativePath && (
        <DynamEnumWatch arrayScope={scope} idPath={identifierRelativePath} labelPath={labelRelativePath} />
      )}
      {scope &&
        elements &&
        fields.map((field, index) => {
          return (
            <div key={index} className={'custom-form u-mb--10 u-mt--10 custom-form-txt'}>
              {label && (
                <h2 className='custom-form__title gov-title--delta'>
                  {index + 1}) {label}
                </h2>
              )}

              <ArrayGroupInsideTxt uiSchema={uiSchema} scope={scope} index={index} />
            </div>
          )
        })}
    </div>
  )
}

const ArrayGroupInsideTxt = React.memo(
  ({ uiSchema, scope, index }: { uiSchema: iUIschema; scope: string; index: number }) => {
    const ui = modifUiSchemaArray(uiSchema, scope!, index).elements
    return <RenderElements uiSchemas={ui} />
  },
)

export default React.memo(ArrayGroupTxt)

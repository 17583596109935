import React, { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import ButtonGov from '../../components/Btns/ButtonGov'
import { scopeFullToPath } from '../../utils/scopeModifs'
import { useElementContext } from '../context/ElementContext'
import { IndexesContextProvider } from '../context/IndexesContext'
import PartGovDescription from '../elements/parts/PartGovDescription'
import PartGovErrorsBlock from '../elements/parts/PartGovErrorsBlock'
import RenderElement from './RenderElement'

const RenderSimpleArray = () => {
  const { uiSchema, dataSchema, id, disabled } = useElementContext()

  const scope = uiSchema.scope || 'undefined'
  const path = scopeFullToPath(scope)
  //TODO defaultni hodnota z prvku ve schematu + datovy typ
  const defautlValue = ''

  const { setValue, getValues } = useFormContext()
  const { fields } = useFieldArray({
    name: path,
    shouldUnregister: true,
  })

  const min = dataSchema?.validations?.minItems ?? 1
  const max = dataSchema?.validations?.maxItems
  const btnAddLabel = 'Přidat'
  const btnRemoveLabel = 'Odebrat'

  const fieldsLength = fields.length || 0
  const isFull = max && fieldsLength >= max
  const isMin = min && fieldsLength <= min

  useEffect(() => {
    const c = min - fieldsLength
    if (min && c > 0) {
      const oldVal = getValues(path) || []
      const newVal = Array(c).fill(defautlValue)
      const val = [...oldVal, ...newVal]
      setValue(path, val)
    }
  }, [min])

  const clickAdd = () => {
    const oldVal = getValues(path)
    oldVal.push(defautlValue)
    setValue(path, oldVal)
  }

  const clickRemove = (index: number) => {
    const oldVal = getValues(path)
    oldVal.splice(index, 1)
    setValue(path, oldVal)
  }

  return (
    <div id={id}>
      <PartGovDescription text={uiSchema.options?.description} />
      <PartGovErrorsBlock />
      {scope &&
        fields.map((field, index) => {
          const uiEl = { ...uiSchema, scope: uiSchema.scope + '/' + index }
          return (
            <div key={field.id} style={{ display: 'flex' }}>
              <div style={{ flex: 'auto', marginRight: 30 }}>
                <IndexesContextProvider index={index}>
                  <RenderElement uiSchema={uiEl} isSimpleArrayItem={true} />
                </IndexesContextProvider>
              </div>
              {!isMin && !disabled && (
                <div className={'btnwrap'} style={{ marginTop: 10 }}>
                  <ButtonGov variant='primary' onClick={() => clickRemove(index)}>
                    {btnRemoveLabel}
                  </ButtonGov>
                </div>
              )}
            </div>
          )
        })}
      {!isFull && !disabled && (
        <div className='btnwrap u-mb--30'>
          <ButtonGov variant='primary' onClick={() => clickAdd()}>
            {btnAddLabel}
          </ButtonGov>
        </div>
      )}
    </div>
  )
}

export default React.memo(RenderSimpleArray)

import { useQuery } from '@tanstack/react-query'
import { useCombobox } from 'downshift'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingInline from '../../../components/Loading/LoadingInline'
import { iOption } from '../../../interfaces/iOption'
import EnumLoader from '../../../services/EnumLoader'
import FormFullInit from '../../../services/FormFullInit'
import { useElementContext } from '../../context/ElementContext'
import { useFormRenderContext } from '../../context/RenderFormContext'
import { useElementEnum } from '../../hooks/useElementEnum'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

class CustomError extends Error {}

const InputAutocomplete = () => {
  const { field, errors } = useControlRegister()
  const { id, required, disabled, path } = useElementContext()
  const { enumKey } = useElementEnum()

  const { formSchemaId } = useFormRenderContext()

  const [searchText, setSearchText] = useState('')
  const enableSearch = !!searchText && searchText.length >= 1

  const querySearch = useQuery(
    ['searchEnum', enumKey, searchText],
    () =>
      EnumLoader.search(formSchemaId!, enumKey!, 20, inputValue)
        .then((options) => {
          if (options.length) {
            return options
          } else {
            throw new CustomError('Zadané hodnotě neodpovídá žádná položka')
          }
        })
        .catch((err) => {
          if (err instanceof CustomError) {
            throw err
          } else {
            throw new Error('Nepodařilo se načíst data z číselníku')
          }
        }),

    {
      enabled: enableSearch,
      cacheTime: 24 * 60 * 60 * 1000,
      staleTime: 24 * 60 * 60 * 1000,
    },
  )

  useEffect(() => {
    if (querySearch.data) {
      setItems(querySearch.data)
    }
  }, [querySearch.data])

  const [items, setItems] = useState<iOption[]>([])
  useEffect(() => {
    if (items.length === 1 && field.value && !inputValue) {
      selectItem(field.value) // kvuli reloadu textu v itemToString po nacteni single hodnoty
    }
  }, [items])

  const [loadingFirst, setLoadingFirst] = useState(true)
  useEffect(() => {
    if (!loadingFirst) {
      FormFullInit.removeInputSelectScopes(path)
    }
  }, [loadingFirst])

  useEffect(() => {
    if (field.value && !inputValue) {
      //nacteni labelu z ulozene value
      EnumLoader.getByCode(formSchemaId!, enumKey!, field.value)
        .then((res) => {
          setLoadingFirst(false)
          setItems(res)
        })
        .catch((err) => {
          toast.error('Chyba načtení hodnoty do číselníku: ' + field.value)
        })
    } else {
      setLoadingFirst(false)
    }
  }, [field.value])

  const loading = querySearch.isFetching
  const loadError = enableSearch ? (querySearch.error as any)?.message : 'Zadejte minimálně 1 znak'

  const loadAdressDebounce = useCallback(
    debounce((text) => {
      setSearchText(text)
    }, 500),
    [],
  )

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
    inputValue,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      // nacteni jen pokud neni label v seznamu? jak bude fungovat pro dlouhe
      const isSingleLoad = items.length === 1 && items[0].label === inputValue
      if (!isSingleLoad) {
        loadAdressDebounce(inputValue)
      }
      if (!inputValue || inputValue?.length === 0) {
        // toast.success('clear ' + id)
        field.onChange('') // musi byt '' - kdyby bylo undefined tak to nacte default hodnotu ktera se nacetla ze souboru
        setSearchText('')
      }
    },
    // inputValue
    // selectedItem: items.find((item) => item.value === field.value),
    selectedItem: field.value || '',
    onSelectedItemChange({ selectedItem }) {
      // field.onChange(selectedItem?.value)
      field.onChange(selectedItem)
    },

    items: items.map((i) => i.value),
    itemToString(itemVal) {
      const item = items.find((item) => item.value === itemVal)
      return item ? item.label : ''
    },
  })

  return (
    <WrapInputGov value={field.value} errors={errors}>
      {/*rhf: {field.value}*/}
      {/*<br />*/}
      {/*inputvalue: {inputValue}*/}
      {/*<br />*/}
      {/*searchText: {searchText}*/}
      {/*<br />*/}
      {/*items: {JSON.stringify(items, null, 2)}*/}
      <div className='gov-autocomplete'>
        <input
          {...getInputProps({
            ref: field.ref,
            onBlur: (e) => {
              // pokud nekdo vlozil kopirovanim tak je jen label - kontrola s nactenym
              const actItemByLabel = items.find((i) => i.label === inputValue)
              if (actItemByLabel) {
                if (field.value !== actItemByLabel.value) {
                  field.onChange(actItemByLabel.value)
                }
              } else {
                if (field.value) {
                  // obnoveni labelu dle vybrane polozky
                  selectItem(field.value)
                } else {
                  //smazani rozepsaneho labelu
                  selectItem(undefined)
                }
              }
              field.onBlur()
            },
            name: field.name,
          })}
          id={id}
          className='gov-form-control__input'
          type='text'
          disabled={disabled}
          aria-required={required ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-labelledby={id}
        />
        {loadingFirst && (
          <div style={{ position: 'absolute', top: 30, left: 20 }}>
            <LoadingInline />
          </div>
        )}

        <ul
          className={`gov-autocomplete__results`}
          style={{ display: isOpen && (items.length || loadError || loading) ? 'block' : 'none' }}
          {...getMenuProps()}
        >
          {isOpen && loading && <li className={`gov-autocomplete__empty`}>načítám</li>}
          {isOpen && loadError && <li className={`gov-autocomplete__empty`}>{loadError}</li>}
          {isOpen &&
            !loading &&
            !loadError &&
            items.map((item, index) => (
              <li
                className={`gov-autocomplete__result ${highlightedIndex === index && 'selected'}`}
                style={{
                  // backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                  fontWeight: selectedItem === item ? 'bold' : 'normal',
                }}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </li>
            ))}
        </ul>
        <PartGovLabel {...getLabelProps()} />
      </div>
    </WrapInputGov>
  )
}

export default InputAutocomplete

class ArrayCounterHelper {
  INPUT_HIDDEN_PREFIXES: string[] = []
  PAD_ZERO = 4

  getNextForPrefix(prefixScope: string) {
    const prefixNums = this.INPUT_HIDDEN_PREFIXES.filter((i) => i.startsWith(prefixScope)).map((i) =>
      parseInt(i.replace(`${prefixScope}-`, '')),
    )
    const nextNum = prefixNums.length ? Math.max(...prefixNums) + 1 : 1
    const numWithPrefix = `${prefixScope}-` + nextNum.toString().padStart(this.PAD_ZERO, '0')
    this.INPUT_HIDDEN_PREFIXES.push(numWithPrefix)
    return numWithPrefix
  }

  deletePrefixId(val: string) {
    // this.INPUT_HIDDEN_PREFIXES = this.INPUT_HIDDEN_PREFIXES.filter((val) => val !== prefixId)
    let index = this.INPUT_HIDDEN_PREFIXES.indexOf(val)
    if (index !== -1) {
      this.INPUT_HIDDEN_PREFIXES.splice(index, 1)
    }
  }

  addPrefixId(val: string) {
    if (!this.INPUT_HIDDEN_PREFIXES.includes(val)) {
      this.INPUT_HIDDEN_PREFIXES.push(val)
    }
  }
}

const ACH = new ArrayCounterHelper()

export default ACH
